// Gatsby supports TypeScript natively!
import { Link, PageProps } from "gatsby";
import React from "react";
import Git from "../../components/git";
import PosterizeBleedDisplayImage from "../../components/images/perlin_bleed_display_image.gif";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import HlslHighlighter from "../../components/highlighters/hlsl-highlighter";
import StyledImg from "../../components/styled-img";
import barswithnoise from '../../images/posterize_bleed_imagesz/bars_with_noise.png';
import colorcorrected from '../../images/posterize_bleed_imagesz/color_corrected.png';
import movingnoise from '../../images/posterize_bleed_imagesz/moving_noise.gif';

// posterize anything section 
import movingscreenspace from '../../images/posterize_bleed_imagesz/moving_screenspace.gif';
import perlinbleed from '../../images/posterize_bleed_imagesz/perlin_bleed.gif';
import perlin from '../../images/posterize_bleed_imagesz/perlin_noise.png';
import posterized from '../../images/posterize_bleed_imagesz/posterized.png';
import posterizedbars from '../../images/posterize_bleed_imagesz/posterized_bars.png';
import posterizedlighting from '../../images/posterize_bleed_imagesz/posterized_lighting.png';
import singlebar from '../../images/posterize_bleed_imagesz/single_line.png';
import stepfunction from '../../images/posterize_bleed_imagesz/stepfunction_4.png';
import worleybleed from '../../images/posterize_bleed_imagesz/worley_bleed.gif';

const DiffusedPosterization = (props: PageProps) => {
  
  return <Layout>
    <SEO title="Diffused Posterization" />
    <div>
		<h1>Diffused Posterization<Git url="https://github.com/smoothslerp/diffused-posterization" /></h1>
		<p>Adding Noise to Posterization of 0-1 (Unity/HLSL)</p>
		<StyledImg src={PosterizeBleedDisplayImage} />

		<p>
			In this tutorial we will look at posterization and a simple technique to add diffused noise to the effect. 
		</p>
			
		<p> The tutorial for this shader is going to be divided into 5 sections: </p>
		<i>
			<ul>
				<li> Posterization </li>
				<li> Line Function </li>
				<li> Adding Noise to Line Function</li>
				<li> Colour Correction </li>
                <li> Application</li>
			</ul>
		</i>

		<h1> Posterization </h1>

            <p>
                The Posterization effect leverages the <u><a target="_blank" href="https://www.desmos.com/calculator/35q479cl8j">step</a></u> function to sub-divide each whole-number 
                interval of a linear function into <i>k</i> parts.
            </p>
            
            <HlslHighlighter code={posterize}/>
            <StyledImg src={stepfunction} caption="k=2, blue is linear increase, red is step increase"/>
            
            <p>
                As we can see above, as <i>x</i> increases linearly, <i>y</i> increases in increments of <i>1/k</i> everytime <i>x</i> crosses the <i>1/k</i> threshold on its own axis.
            </p>

            <p>
                When applied to a uv-texture, the step function looks like this: 
            </p>
            <HlslHighlighter greenList={[2]}code={postCode}/>
            <StyledImg src={posterized} caption="k=7"/>

            <p>
                As we have seen before, a uv-texture ranges from <i>0</i> to <i>1</i> on each axis. In the code above, we have sub-divided the <i>y-axis</i> into 7 parts. This has the effect of 
                giving us 7 distinct bands of colour that are all still in the range of <i>0</i> to <i>1</i>.
            </p>
            
		<h1> Line Function </h1>

            <p>
                Next, we would like to draw lines at the edge of each colour band like so: 
            </p>

            <StyledImg src={posterizedbars} />

            <p>
                Let's start by drawing a single red line. 
            </p>

            <StyledImg src={singlebar} caption="A single line drawn at 0.5, width 0.1"/>
            <HlslHighlighter code={postWithSingleBar} greenList={[4,5,8]} />
            
            <p>
                The above code checks whether the current value of the y-axis is less than <i>0.5</i> while also checking if it is less than <i>0.4</i>. If the value is in the sweet spot where it is less than <i>0.5</i> but 
                greater than <i>0.4</i>, it assigns a value of <i>1</i> to the variable <b><i>bar</i></b>.
            </p>

            <p>
                It then uses <b><i>bar</i></b> to blend with the posterized colour and red. 
            </p>

            <p>
                Now that we can draw a single line of a certain width anywhere on the uv-texture, we use the same technique to draw a line of width <i>0.1</i> at our posterized values on the y-axis:
            </p>

            <HlslHighlighter code={postWithHorizBars} greenList={[4]} />
            <StyledImg src={posterizedbars} caption="Success!"/>


		<h1> Adding Noise to Line Function </h1>

            <p>
                With the basics of posterization and posterized line drawing under our belt, we can move on to doing neat things with it. In the previous section we fixed our 
                line widths to <i>0.1</i>. In this section we will vary the line widths by a random variable. 
            </p>

            <StyledImg src={perlin} caption="Perlin Noise Texture"/>
            <HlslHighlighter code={HorizBarsWithNoise} greenList={[4,5]} />
            
            <p>
                In the code above, we sample the Noise Texture which gives us a value between <i>0</i> and <i>1</i>. We use this value to vary our line width. We also divide the noise by <i>_K</i> to scale the 
                noise <b>down</b> such that if we have more colour bands due to our posterize function, the less we expand the width of each line due to noise.

            </p>
            
            <StyledImg src={barswithnoise} caption="k=3"/>

            <p> Finally, to make the effect more interesting, we offset the noise texture coordinates by time. </p>

            <HlslHighlighter code={MovingNoise} greenList={[4,5]}/>
            <StyledImg src={movingnoise} />

            <h1> Colour Correction </h1>

            <p>
                Next, we want to replace the red colour with one that is appropriate to make it look like one band of colour from each posterization level is diffusing into the one below. 
            </p>

            <HlslHighlighter code={HorizBarsWithNoisecolourCorrected} greenList={[9,6]}/>
        
            <p>
                We initialize a variable <i><b>invK</b></i> which is essentially the gap between each colour band. If <i>k=2</i>, the interval <i>0</i> to <i>1</i> is divided into 2 equal intervals, and so <i>1/2</i> is
                the difference between each colour band. 
            </p>

            <p>
                Each noisy, red line diffuses into a preceeding colour band, and we want to assign it the colour of the <b>next</b> colour band. We do this by adding <b><i>invK</i></b> (the gap value) to 
                the current  <i><b>post</b></i> value.
            </p>

            <StyledImg src={colorcorrected} caption="k=3"/>

        <h1> Application </h1>
        
            <p>
                Diffused Posterization is a pretty reusable image effect since it works on any range from <i>0</i> to <i>1</i>; although this is pretty much the end of this tutorial we will continue to build 
                the advertised effect.
            </p>

            <p>
                We start by showing what posterization looks like on diffused lighting: <b><i>diff</i></b> (<i>NDotL</i>) 
            </p>

            <HlslHighlighter code={posterizedLighting}/>
            <StyledImg src={posterizedlighting}/>
            
            <p>
                We then proceed to take the posterization effect out of the <b><i>surf</i></b> function so that we can move it into our lighting function. Instead we assign the actual 
                colour of the texture assigned to the <b>Albedo</b>. 
            </p>
            <p>
                We also setup a patterned, screenspace texture as <u><Link to="spiderverse#screenspace" style={{ color: `black`, textDecoration: `none` }}>we have seen in the previous tutorial</Link></u>, but
                this time we use it to sample <b>and pass</b> noise to the lighting function. 
            </p>

            <HlslHighlighter code={takeStuffOutOfSurf} redList={[2,12,13,14]} greenList={[4,5,6,7,9,10,19]}/>

            <p>
                The screen space uv-textures that are used to sample noise look like this (code not shown):
            </p>

            <StyledImg src={movingscreenspace}/>

            <p>
                We continue to diffuse the posterized lighting:
            </p>

            <HlslHighlighter code={lighting} greenList={[5,6,8,9,12]}/>

            <StyledImg src={perlinbleed} caption="Perlin Noise"/>
            <StyledImg src={worleybleed} caption="Worley Noise"/>

        <p>
            <i>
                The full shader and sample scene 
                can be found <u><a target="_blank" href="https://github.com/smoothslerp/diffused-posterization/blob/master/Assets/diffused-posterization.shader">
                in this repository</a></u>. Thanks for tuning in, until next time!
                </i>
        </p>

		
	</div>
  	</Layout>
}

const posterize = `
	float posterize(float v, float k) {
		return ceil(v*k)/k;
	}`

const postCode = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, 7);

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = post;
		o.Alpha = 1.0;
	}
`;


const postWithSingleBar = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);
		
		fixed bar = step(IN.uv_MainTex.y, 0.5) - step(IN.uv_MainTex.y, 0.4);
		fixed4 c = (1-bar) * post + bar * fixed4(1,0,0,1);  

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb;
		o.Alpha = c.a;
	}
`;

const postWithHorizBars = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);
		
		fixed bar = step(IN.uv_MainTex.y, post) - step(IN.uv_MainTex.y, post-0.1);
		fixed4 c = (1-bar) * post + bar * float4(1,0,0,1);  

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb;
		o.Alpha = c.a;
	}
`;

const HorizBarsWithNoise = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);
		
		fixed noise = tex2D(_NoiseTex, IN.uv_NoiseTex);
		fixed bar = step(IN.uv_MainTex.y, post) - step(IN.uv_MainTex.y, post-noise/_K);
		fixed4 c = (1-bar) * post + bar * float4(1,0,0,1);  

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb;
		o.Alpha = c.a;
	}
`;

const MovingNoise = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);
		
		float2 textureCoordinate = IN.uv_NoiseTex + float2(_Time.y/10, _Time.y/20);
		fixed noise = tex2D(_NoiseTex, textureCoordinate);

		fixed bar = step(IN.uv_MainTex.y, post) - step(IN.uv_MainTex.y, post-noise/_K);
		fixed4 c = (1-bar) * post + bar * float4(1,0,0,1); 

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb;
		o.Alpha = c.a;
	}
`;

const HorizBarsWithNoisecolourCorrected = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);
		
		float2 textureCoordinate = IN.uv_NoiseTex + float2(_Time.y/10, _Time.y/20);
		fixed noise = tex2D(_NoiseTex, textureCoordinate);
		float invK = 1.0/_K;

		fixed bar = step(IN.uv_MainTex.y, post) - step(IN.uv_MainTex.y, post-noise/_K);
		fixed4 c = (1-bar) * post + bar * (post+invK); 

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb;
		o.Alpha = c.a;
	}
`;

const takeStuffOutOfSurf = `
	void surf (Input IN, inout SurfaceOutputCustom o) {
		fixed post = posterize(IN.uv_MainTex.y, _K);

		float2 textureCoordinate = IN.screenPos.xy / IN.screenPos.w; // perspective divide
		float aspect = _ScreenParams.x / _ScreenParams.y;
		textureCoordinate.x = textureCoordinate.x * aspect;
		textureCoordinate += float2(_Time.y/10, _Time.y/20);
		
		fixed noise = tex2D (_NoiseTex,  frac(textureCoordinate * _NoiseScale)); 
		fixed4 c = tex2D(_MainTex, IN.uv_MainTex);

		float invK = 1.0/_K;
		fixed bar = step(IN.uv_MainTex.y, post) - step(IN.uv_MainTex.y, post-noise/_K);
		fixed4 c = (1-bar) * post + bar * (post + invK); 

		o.Normal = UnpackNormal(tex2D(_NormalTex, IN.uv_NormalTex));
		o.Albedo = c.rgb; 
		o.Alpha = c.a;
		o.noise = noise; 
	}
`;

const posterizedLighting = `
	half4 LightingCustom (SurfaceOutputCustom s, half3 lightDir, half3 viewDir) {
		float diff = max(0, dot(s.Normal, lightDir));
		fixed post = posterize(diff, _K);

		half4 col;
		col = post;
		col.a = s.Alpha;
		
		return col;
	}
`

const lighting = `
half4 LightingCustom (SurfaceOutputCustom s, half3 lightDir, half3 viewDir) {
	float diff = max(0, dot(s.Normal, lightDir));
	fixed post = posterize(diff, _K);
	
	float invK = 1.0/_K;
	fixed bar = step(diff, post) - step(diff, post-s.noise/_K);
	
	fixed b = (1-bar) * post + bar * (post+invK); 
	half3 l = (s.Albedo * b + _AmbientColour * _AmbientStrength); 
	
	half4 col;
	col.rgb = l;
	col.a = s.Alpha;
	
	return col;
}`

const debugScreenspace = `
	o.Albedo = float3(frac(textureCoordinate * _NoiseScale), 0);
`

export default DiffusedPosterization
